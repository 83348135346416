import React, { Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { logout } from "./components/pages/Login/LoginReducer/loginActions";
import Header from "../src/layout/Header";
import Routes from "./Routes/index";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const history = useNavigate();
  const location = useLocation();
  const userName = useSelector((state) => state.common.userName);
  const authKey = useSelector((state) => state.common.authKey);
  const dispatch = useDispatch();

  const handleLogOut = () => {
    dispatch(logout());
  };

  return (
    <div className="App App-App-1">
      <Header
        history={history}
        isLoggedin={!!authKey}
        userName={userName}
        handleLogOut={handleLogOut}
      />
      <Suspense fallback={<CircularProgress />}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <Routes history={history} location={location} isLoggedin={!!authKey} />
      </Suspense>
    </div>
  );
};

export default App;
