import APP_EVENTS from "./AppEvents";
import get from "lodash/get";

const initialState = {
  textStories: {
    isLoading: false,
    isError: false,
    data: [],
    page: 0,
  },
  videoStories: {
    isLoading: false,
    isError: false,
    data: [],
    page: 0,
  },
  preferences: {
    isLoading: false,
    isError: false,
    data: [],
  },
  articleDetails: {
    isLoading: false,
    isError: false,
    data: [],
  },
  filterValue: {
    fromTS: "",
    toTS: "",
  },
  range: {
    from: "",
    to: "",
  },
  synonyms: "",
  preferencesArr: [],
  rangeSelected: "",
  textSelected: [],
  videoSelected: [],
  isFilterApplied: false,
  isTextSubmitEnabled: false,
  isVideoSubmitEnabled: false,
  isBulkSubmitLoading: false,
  isSingleSubmitLoading: false,
  isSendNotificationLoading: false,
  isAPIError: true,
};

function getIndex(item, id) {
  return item.id === id;
}

const StoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // UPDATE TABLE
    case `${APP_EVENTS.UPDATE_TABLE}_EFFECT`:
      // For Text Stories
      if (get(action, "payload.isTextStory") || false) {
        const index = state.textStories.data.items.findIndex((item) =>
          getIndex(item, get(action, "payload.id"))
        );

        state.textStories.data.items[index][action.payload.label] =
          action.payload.isChecked;
      }

      // For Video Stories
      if (get(action, "payload.isVideoStory") || false) {
        const index = state.videoStories.data.items.findIndex((item) =>
          getIndex(item, get(action, "payload.id"))
        );

        state.videoStories.data.items[index][action.payload.label] =
          action.payload.isChecked;
      }

      state = {
        ...state,
      };
      break;
    // UPDATE ARTICLE CONTENT TAG
    case `${APP_EVENTS.UPDATE_ARTICLE_CONTENT_TAG}_EFFECT`:
      // For Text Stories
      if (get(action, "payload.id")) {
        const index = state.textStories.data.items.findIndex((item) =>
          getIndex(item, get(action, "payload.id"))
        );

        state.textStories.data.items[index]["contentTag"] =
          action.payload.selected;
      }
      state = {
        ...state,
      };
      break;
    // UPDATE ARTICLE MEDIA URL
    case `${APP_EVENTS.UPDATE_ARTICLE_MEDIA_URL}_EFFECT`:
      // For Text Stories
      if (get(action, "payload.id")) {
        const index = state.textStories.data.items.findIndex((item) =>
          getIndex(item, get(action, "payload.id"))
        );

        state.textStories.data.items[index]["mediaURL"] = action.payload.url;
      }
      state = {
        ...state,
      };
      break;

    // UPDATE ROW
    case `${APP_EVENTS.HANDLE_CHECKBOX}_EFFECT`:
      // For Text Stories
      if (get(action, "payload.isTextStory") || false) {
        // if checked
        if (get(action, "payload.isChecked")) {
          const index = state.textSelected.findIndex((item) =>
            getIndex(item, get(action, "payload.id"))
          );
          // checking if id already exists
          if (index === -1) {
            state = {
              ...state,
              textSelected: [...state.textSelected, action.payload.id],
            };
          }
        } else {
          const index = state.textSelected.indexOf(get(action, "payload.id"));
          // checking if index is not -1
          if (index !== -1) {
            state.textSelected.splice(index, 1);
          }
        }
      }

      // For Video Stories
      if (get(action, "payload.isVideoStory") || false) {
        // if checked
        if (get(action, "payload.isChecked")) {
          const index = state.videoSelected.findIndex((item) =>
            getIndex(item, get(action, "payload.id"))
          );
          // checking if id already exists
          if (index === -1) {
            state = {
              ...state,
              videoSelected: [...state.videoSelected, action.payload.id],
            };
          }
        } else {
          const index = state.videoSelected.indexOf(get(action, "payload.id"));
          // checking if index is not -1
          if (index !== -1) {
            state.videoSelected.splice(index, 1);
          }
        }
      }

      state = {
        ...state,
        isTextSubmitEnabled: !!state.textSelected.length,
        isVideoSubmitEnabled: !!state.videoSelected.length,
      };
      break;

    // RANGE
    case `${APP_EVENTS.SET_RANGE}_EFFECT`:
      state = {
        ...state,
        range: action.payload,
      };
      break;

    // SYNONYMS
    case `${APP_EVENTS.SET_SYNONYMS}_EFFECT`:
      state = {
        ...state,
        synonyms: action.payload,
      };
      break;

    // SINGLE SUBMIT
    case APP_EVENTS.POST_SINGLE_SUBMIT_LOADING:
      state = {
        ...state,
        isSingleSubmitLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.POST_SINGLE_SUBMIT_ERROR:
      state = {
        ...state,
        isSingleSubmitLoading: false,
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.POST_SINGLE_SUBMIT}_EFFECT`:
      state = {
        ...state,
        isSingleSubmitLoading: false,
        isAPIError: false,
      };
      break;

    // BULK SUBMIT
    case APP_EVENTS.POST_BULK_SUBMIT_LOADING:
      state = {
        ...state,
        isBulkSubmitLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.POST_BULK_SUBMIT_ERROR:
      state = {
        ...state,
        isBulkSubmitLoading: false,
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.POST_BULK_SUBMIT}_EFFECT`:
      state = {
        ...state,
        isBulkSubmitLoading: false,
        isAPIError: false,
      };
      break;

    // REJECT
    case APP_EVENTS.REJECT_LOADING:
      state = {
        ...state,
        isRejectLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.REJECT_ERROR:
      state = {
        ...state,
        isRejectLoading: false,
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.REJECT}_EFFECT`:
      state = {
        ...state,
        isRejectLoading: false,
        isAPIError: false,
      };

      if (action.payload.pageType === "textPage") {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.textStories.page,
            data: {
              ...state.textStories.data,
              items:
                state.textStories.data.items.filter(
                  (el) => el.id !== action.payload.id
                ) || [],
            },
          },
        };
      } else {
        state = {
          ...state,
          videoStories: {
            isLoading: false,
            isError: false,
            page: state.videoStories.page,
            data: {
              ...state.videoStories.data,
              items:
                state.videoStories.data.items.filter(
                  (el) => el.id !== action.payload.id
                ) || [],
            },
          },
        };
      }
      break;

    // SEND_NOTIFICATION
    case APP_EVENTS.SEND_NOTIFICATION_LOADING:
      state = {
        ...state,
        isSendNotificationLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.SEND_NOTIFICATION_ERROR:
      state = {
        ...state,
        isSendNotificationLoading: false,
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.SEND_NOTIFICATION}_EFFECT`:
      state = {
        ...state,
        isSendNotificationLoading: false,
        isAPIError: false,
      };

      break;

    //UPDATE_SUMMARY
    case APP_EVENTS.UPDATE_SUMMARY_LOADING:
      state = {
        ...state,
        isSummaryUpdateLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.UPDATE_SUMMARY_ERROR:
      state = {
        ...state,
        isRejectLoading: false,
        isAPIError: true,
      };
      break;
    case `${APP_EVENTS.UPDATE_SUMMARY}_EFFECT`:
      if (action.payload.pageType === "textPage") {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.textStories.page,
            data: {
              ...state.textStories.data,
              items: state.textStories.data.items.map((el) => {
                if (el.id === action.payload.id) {
                  el.resoomerSummary = action.payload.updatedSummary;
                }
                return el;
              }),
            },
          },
        };
      } else {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.videoStories.page,
            data: {
              ...state.videoStories.data,
              items: state.videoStories.data.items.map((el) => {
                if (el.id === action.payload.id) {
                  el.resoomerSummary = action.payload.updatedSummary;
                }
                return el;
              }),
            },
          },
        };
      }
      break;

    case APP_EVENTS.UPDATE_TITLE_LOADING:
      state = {
        ...state,
        isTitleUpdateLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.UPDATE_TITLE_ERROR:
      state = {
        ...state,
        isTitleUpdateLoading: false,
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.UPDATE_TITLE}_EFFECT`:
      if (action.payload.pageType === "textPage") {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.textStories.page,
            data: {
              ...state.textStories.data,
              items: state.textStories.data.items.map((el) => {
                if (el.id === action.payload.id) {
                  el.title = action.payload.updatedTitle;
                }
                return el;
              }),
            },
          },
        };
      } else {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.videoStories.page,
            data: {
              ...state.videoStories.data,
              items: state.videoStories.data.items.map((el) => {
                if (el.id === action.payload.id) {
                  el.title = action.payload.updatedTitle;
                }
                return el;
              }),
            },
          },
        };
      }
      break;

    case APP_EVENTS.UPDATE_DERIVED_TAGS_LOADING:
      state = {
        ...state,
        isTitleUpdateLoading: true,
        isAPIError: false,
      };
      break;

    case APP_EVENTS.UPDATE_DERIVED_TAGS_ERROR:
      state = {
        ...state,
        isTitleUpdateLoading: false,
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.UPDATE_DERIVED_TAGS}_EFFECT`:
      if (action.payload.pageType === "textPage") {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.textStories.page,
            data: {
              ...state.textStories.data,
              items: state.textStories.data.items.map((el) => {
                if (el.id === action.payload.id) {
                  el.derivedTags = action.payload.updatedDerivedTags.split(",");
                }
                return el;
              }),
            },
          },
        };
      } else {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            page: state.videoStories.page,
            data: {
              ...state.videoStories.data,
              items: state.videoStories.data.items.map((el) => {
                if (el.id === action.payload.id) {
                  el.derivedTags = action.payload.updatedDerivedTags.split(",");
                }
                return el;
              }),
            },
          },
        };
      }
      break;
    // FILTER
    case `${APP_EVENTS.SET_FILTER}_EFFECT`:
      state = {
        ...state,
        filterValue: action.payload,
        isFilterApplied:
          (!!get(action, "payload.toTS") && !!get(action, "payload.fromTS")) ||
          false,
        isAPIError: false,
      };
      break;

    // STORIES
    case APP_EVENTS.GET_STORIES_LOADING:
    case APP_EVENTS.GET_SEARCH_STORIES_LOADING:
      if (action.payload.isTextStory) {
        state = {
          ...state,
          textStories: {
            isLoading: true,
            isError: false,
            data: action.payload || [],
          },
          isAPIError: false,
        };
      } else if (action.payload.isVideoStory) {
        state = {
          ...state,
          videoStories: {
            isLoading: true,
            isError: false,
            data: action.payload || [],
          },
          isAPIError: false,
        };
      }
      break;

    case `${APP_EVENTS.GET_STORIES}_EFFECT`:
    case `${APP_EVENTS.GET_SEARCH_STORIES}_EFFECT`:
      if (action.payload.isTextStory) {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: false,
            data: get(action, "payload.data") || [],
            page: get(action, "payload.page") || 0,
          },
          isAPIError: false,
          preferencesArr: get(action, "payload.selectedTag") || [],
          rangeSelected: get(action, "payload.rangeSelected") || [],
        };
      } else if (action.payload.isVideoStory) {
        state = {
          ...state,
          videoStories: {
            isLoading: false,
            isError: false,
            data: get(action, "payload.data") || [],
            page: get(action, "payload.page") || 0,
          },
          isAPIError: false,
          preferencesArr: get(action, "payload.selectedTag") || [],
          rangeSelected: get(action, "payload.rangeSelected") || [],
        };
      }
      break;

    case APP_EVENTS.GET_STORIES_ERROR:
    case APP_EVENTS.GET_SEARCH_STORIES_ERROR:
      if (action.payload.isTextStory) {
        state = {
          ...state,
          textStories: {
            isLoading: false,
            isError: true,
            data: [],
          },
          isAPIError: true,
        };
      } else if (action.payload.isVideoStory) {
        state = {
          ...state,
          videoStories: {
            isLoading: false,
            isError: true,
            data: [],
          },
          isAPIError: true,
        };
      }
      break;

    // PREFERENCES
    case `${APP_EVENTS.GET_PREFERENCES_LOADING}_EFFECT`:
      state = {
        ...state,
        preferences: {
          isLoading: false,
          isError: false,
          data: action.payload || [],
        },
        isAPIError: false,
      };
      break;

    case `${APP_EVENTS.GET_PREFERENCES}_EFFECT`:
      state = {
        ...state,
        preferences: {
          isLoading: true,
          isError: false,
          data: action.payload.data || [],
        },
        isAPIError: false,
      };
      break;

    case `${APP_EVENTS.GET_PREFERENCES_ERROR}_EFFECT`:
      state = {
        ...state,
        preferences: {
          isLoading: false,
          isError: true,
          data: [],
        },
        isAPIError: true,
      };
      break;

    // ARTICLE DETAILS
    case `${APP_EVENTS.GET_DATA_LOADING}_EFFECT`:
      state = {
        ...state,
        articleDetails: {
          isLoading: false,
          isError: false,
          data: action.payload || [],
        },
        isAPIError: false,
      };
      break;

    case `${APP_EVENTS.GET_DATA}_EFFECT`:
      state = {
        ...state,
        articleDetails: {
          isLoading: true,
          isError: false,
          data: [],
        },
        isAPIError: false,
      };
      break;

    case `${APP_EVENTS.GET_DATA_ERROR}_EFFECT`:
      state = {
        ...state,
        articleDetails: {
          isLoading: false,
          isError: true,
          data: [],
        },
        isAPIError: true,
      };
      break;

    case `${APP_EVENTS.CLEAR_DATA}_EFFECT`:
      state = initialState;
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default StoriesReducer;
