import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import OverlayLoader from "../../../common/overlayLoader";
import routeConfig from "../../../constants/routeConfig";
import "./Login.css";
import "./LoginPage.css";

class LoginPage extends Component {
  constructor() {
    super();
    this.state = {
      userName: "",
      password: "",
      loginProgress: true,
      err_msg_userName: "",
      err_msg_password: "",
      err_msg_auth: "",
      err_msg_getDetals: "",
      getDetailsResponse: null,
    };
  }

  componentWillMount() {
    localStorage.removeItem("pageObj");
  }

  onChangeUsername = (e) => {
    this.setState({
      userName: e.target.value.trim(),
      err_msg_userName: "",
      err_msg_auth: "",
      err_msg_getDetals: "",
    });
  };

  onChangePassword = (e) => {
    this.setState({
      password: e.target.value,
      err_msg_password: "",
      err_msg_auth: "",
      err_msg_getDetals: "",
    });
  };

  enterPressed = (event) => {
    var code = event.keyCode || event.which;

    if (code === 13) {
      if (!event.target.value) {
        this.setState({
          err_msg_userName: "",
          err_msg_password: "Please enter password",
          err_msg_auth: "",
        });
      }
    }
  };

  onClick = () => {
    const { userName, password } = this.state;
    const { history, login } = this.props;

    if (userName === "") {
      this.setState({
        err_msg_auth: "",
        err_msg_password: "",
        err_msg_userName: "Please enter userName",
        err_msg_getDetals: "",
      });
    } else if (password === "") {
      this.setState({
        err_msg_userName: "",
        err_msg_password: "Please enter password",
        err_msg_auth: "",
        err_msg_getDetals: "",
      });
    } else {
      const data = {
        userName,
        password,
        email: `${userName}@bigshyft.com`,
      };
      this.setState({ loginProgress: false });

      new Promise((resolve, reject) => {
        login({ data, resolve, reject });
      })
        .then(() => {
          history(routeConfig.actions);
        })
        .catch(() => {
          this.setState({
            err_msg_auth: "",
            err_msg_userName: "",
            err_msg_password: "",
            err_msg_getDetals: "Some error in login please try later",
            loginProgress: true,
          });
        });
    }
  };

  render = () => {
    const {
      loginProgress,
      isCaptchaRequired,
      userName,
      password,
      err_msg_password,
      err_msg_userName,
      err_msg_getDetals,
      err_msg_auth,
    } = this.state;

    return (
      <div className="login_form_container">
        <div className="container" noValidate autoComplete="off">
          <h2>Techminis Login</h2>
          <TextField
            id="username-input"
            label="Username"
            className="textField"
            type="text"
            value={userName}
            name="userName"
            placeholder="Username"
            margin="normal"
            error={["Please enter userName"].includes(err_msg_userName)}
            helperText={err_msg_userName}
            onChange={this.onChangeUsername}
            onKeyPress={this.enterPressed}
          />
          <div className="password">
            <TextField
              id="password-input"
              label="Password"
              className="textField"
              type="password"
              value={password}
              name="password"
              placeholder="Password"
              margin="normal"
              error={["Please enter password"].includes(err_msg_password)}
              helperText={err_msg_password}
              onChange={this.onChangePassword}
              onKeyPress={this.enterPressed}
            />
          </div>
          <button className="loginBtn" onClick={this.onClick}>
            Login
          </button>
          {!!err_msg_auth && (
            <div className="fontRed">{!isCaptchaRequired && err_msg_auth}</div>
          )}
          {!!err_msg_getDetals && (
            <div className="fontRed">{err_msg_getDetals}</div>
          )}
          {!loginProgress && <OverlayLoader />}
        </div>
      </div>
    );
  };
}

export default LoginPage;
