import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware from "redux-saga";
import rootReducer from "./reducers";
import rootSaga from "./sagas";
import stateLoader from "./stateLoader";

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const Store = createStore(
  rootReducer,
  stateLoader.loadState(),
  composeEnhancers(applyMiddleware(sagaMiddleware))
);
Store.subscribe(() => {
  const state = { ...Store.getState() };
  stateLoader.saveState(state);
});

sagaMiddleware.run(rootSaga);
export default Store;
