const routeConfig = {
  root: "/",
  actions: "/actions",
  login: "/login",
  textStories: "/textStories",
  videoStories: "/videoStories",
  prioritised: "/prioritised",
  webStories: "/web-stories",
  dragDrop: "/drag-drop",
  postTemplate: "/post-template",
  createStory: "/create-story",
  storiesListing: "/stories-listing",
  editStory: "edit-story/mergedContentId/:mergedContentId",
};

export default routeConfig;
