import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Person from "@material-ui/icons/Person";
import routeConfig from "../../../constants/routeConfig";
import "./Username.css";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogoutVisible: false,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("recId")) {
      this.props.setRecruiterId?.(localStorage.getItem("recId"));
    }
  }

  handleLogoutClick = () => {
    const { history, handleLogOut } = this.props;
    handleLogOut();
    history(routeConfig.login);
  };

  toggleVisibility = () => {
    const { isLogoutVisible } = this.state;
    this.setState({ isLogoutVisible: !isLogoutVisible });
  };

  render() {
    const { agentName } = this.props;
    const { isLogoutVisible } = this.state;
    let logoutTemplate = "";
    if (![undefined, null].includes(agentName)) {
      logoutTemplate = (
        <div className="username-wrapper" onClick={this.toggleVisibility}>
          <div className="username">
            <Avatar className="avatar" style={{ color: "black" }}>
              <Person />
            </Avatar>
            <span> Hello, {agentName}</span>
          </div>
          {isLogoutVisible && (
            <div className="user-dropdown-wrapper">
              <p className="user-dropdown hide">
                <span onClick={this.handleLogoutClick}>Logout</span>
              </p>
            </div>
          )}
        </div>
      );
    }
    return this.props.redirectTo ? (
      <Navigate to={routeConfig.login} />
    ) : (
      logoutTemplate
    );
  }
}

export default Logout;
