import React, { lazy } from "react";
import { useSelector } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import LoginPage from "../components/pages/Login";
import routeConfig from "../constants/routeConfig";

const ActionPage = lazy(() =>
  import(/* webpackChunkName: "ActionPage" */ "../components/pages/ActionPage")
);

const Stories = lazy(() =>
  import(/* webpackChunkName: "Stories" */ "../components/pages/Stories")
);

const PrioritisedList = lazy(() =>
  import(
    /* webpackChunkName: "Stories" */ "../components/pages/PrioritisedList"
  )
);

const WebStories = lazy(() =>
  import(/* webpackChunkName: "WebStories" */ "../components/pages/WebStories")
);

const AddTemplatePage = lazy(() =>
  import(
    /* webpackChunkName: "AddTemplatePage" */ "../components/pages/AddTemplatePage"
  )
);

const CreateStoryPage = lazy(() =>
  import(
    /* webpackChunkName: "CreateStoryPage" */ "../components/pages/CreateStoryPage"
  )
);

const DragAndDrop = lazy(() =>
  import(
    /* webpackChunkName: "DragAndDrop" */ "../components/pages/DragAndDrop"
  )
);

const StoriesListingPage = lazy(() =>
  import(
    /* webpackChunkName: "StoriesListingPage" */ "../components/pages/StoriesListingPage"
  )
);

const EditStoryPage = lazy(() =>
  import(
    /* webpackChunkName: "EditStoryPage" */ "../components/pages/EditStoryPage"
  )
);

const Routing = (props) => {
  const isLoggedIn = useSelector((state) => !!state.common.authKey);

  return (
    <Routes>
      <Route
        path={routeConfig.actions}
        exact
        element={
          isLoggedIn ? <ActionPage {...props} /> : <Navigate to="/login" />
        }
      />
      <Route
        path={routeConfig.login}
        exact
        element={<LoginPage {...props} />}
      />
      <Route
        path={routeConfig.textStories}
        exact
        element={isLoggedIn ? <Stories {...props} /> : <Navigate to="/login" />}
      />
      <Route
        path={routeConfig.videoStories}
        exact
        element={isLoggedIn ? <Stories {...props} /> : <Navigate to="/login" />}
      />
      <Route
        path={routeConfig.prioritised}
        exact
        element={
          isLoggedIn ? <PrioritisedList {...props} /> : <Navigate to="/login" />
        }
      />
      <Route
        path={routeConfig.webStories}
        exact
        element={
          isLoggedIn ? <WebStories {...props} /> : <Navigate to="/login" />
        }
      />
      <Route
        path={routeConfig.postTemplate}
        exact
        element={
          isLoggedIn ? <AddTemplatePage {...props} /> : <Navigate to="/login" />
        }
      />
      <Route
        path={routeConfig.createStory}
        exact
        element={
          isLoggedIn ? <CreateStoryPage {...props} /> : <Navigate to="/login" />
        }
      />
      <Route
        path={routeConfig.storiesListing}
        exact
        element={
          isLoggedIn ? (
            <StoriesListingPage {...props} />
          ) : (
            <Navigate to="/login" />
          )
        }
      />
      <Route
        path={routeConfig.editStory}
        exact
        element={
          isLoggedIn ? <EditStoryPage {...props} /> : <Navigate to="/login" />
        }
      />
      <Route path={routeConfig.dragDrop} exact element={<DragAndDrop />} />
      <Route
        path="/"
        element={
          !isLoggedIn ? (
            <Navigate to={routeConfig.login} />
          ) : (
            <Navigate to={routeConfig.actions} />
          )
        }
      />
    </Routes>
  );
};
export default Routing;
