import APP_EVENTS from "./AppEvents";

const initialState = {
  isLoading: false,
  isError: false,
  userName: "",
  authKey: null,
};

const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${APP_EVENTS.LOGOUT}_EFFECT`:
      state = initialState;
      localStorage.clear();
      sessionStorage.clear();
      break;

    case `${APP_EVENTS.LOGIN}_EFFECT`:
      state = {
        ...state,
        isLoading: false,
        isError: false,
        userName: action.payload.userName || "",
        authKey: action.payload.token || "",
      };
      break;

    case `${APP_EVENTS.LOGIN_LOADING}_EFFECT`:
      state = {
        ...state,
        isLoading: true,
        isError: false,
        userName: null,
        authKey: null,
      };
      break;

    case `${APP_EVENTS.LOGIN_ERROR}_EFFECT`:
      state = {
        ...state,
        isLoading: false,
        isError: true,
        userName: null,
        authKey: null,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default commonReducer;
