import keymirror from "keymirror";

const APP_EVENTS = keymirror({
  GET_PREFERENCES: null,
  GET_PREFERENCES_LOADING: null,
  GET_PREFERENCES_ERROR: null,
  GET_SEARCH_STORIES: null,
  GET_SEARCH_STORIES_LOADING: null,
  GET_SEARCH_STORIES_ERROR: null,
  GET_STORIES: null,
  GET_STORIES_LOADING: null,
  GET_STORIES_ERROR: null,
  POST_BULK_SUBMIT: null,
  POST_BULK_SUBMIT_LOADING: null,
  POST_BULK_SUBMIT_ERROR: null,
  POST_SINGLE_SUBMIT: null,
  POST_SINGLE_SUBMIT_LOADING: null,
  POST_SINGLE_SUBMIT_ERROR: null,
  GET_DATA: null,
  GET_DATA_LOADING: null,
  GET_DATA_ERROR: null,
  SET_FILTER: null,
  CLEAR_FILTER: null,
  UPDATE_TABLE: null,
  SINGLE_SUBMIT: null,
  BULK_SUBMIT: null,
  HANDLE_CHECKBOX: null,
  SET_RANGE: null,
  CLEAR_DATA: null,
  REJECT: null,
  REJECT_LOADING: null,
  REJECT_ERROR: null,
  UPDATE_ARTICLE_MEDIA_URL: null,
  UPDATE_ARTICLE_CONTENT_TAG: null,
  UPDATE_SUMMARY_LOADING: null,
  UPDATE_SUMMARY_ERROR: null,
  UPDATE_SUMMARY: null,
  ERROR: null,
  SET_SYNONYMS: null,
  UPDATE_TITLE: null,
  UPDATE_TITLE_LOADING: null,
  UPDATE_TITLE_ERROR: null,
  UPDATE_DERIVED_TAGS: null,
  UPDATE_DERIVED_TAGS_LOADING: null,
  UPDATE_DERIVED_TAGS_ERROR: null,
  SEND_NOTIFICATION: null,
  SEND_NOTIFICATION_LOADING: null,
  SEND_NOTIFICATION_ERROR: null,
});

export default APP_EVENTS;
