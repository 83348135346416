import { getStorage, setStorage } from "./services/storageService";

export default class StateLoader {
  static sessionReducers = {};

  static loadState() {
    try {
      const serializedState = getStorage("appstate") || {};
      return serializedState;
    } catch (err) {
      return this.initializeState();
    }
  }

  static saveState(state) {
    try {
      setStorage("appstate", { ...state, ...this.sessionReducers });
    } catch (err) {}
  }
  static initializeState() {
    return {
      // state object
    };
  }
}
