import APP_EVENTS from "./AppEvents";
import URLS from "../../../../constants/URLS";

export const login = ({ data, resolve, reject }) => {
  return {
    type: `${APP_EVENTS.LOGIN}_WATCHER`,
    action_type: `${APP_EVENTS.LOGIN}_EFFECT`,
    url: URLS.verifyToken,
    data,
    resolve,
    reject,
  };
};

export const logout = () => {
  return {
    type: `${APP_EVENTS.LOGOUT}_WATCHER`,
    action_type: `${APP_EVENTS.LOGOUT}_EFFECT`,
  };
};
