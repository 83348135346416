import { takeLatest, put, call, takeEvery } from "redux-saga/effects";
import APP_EVENTS from "./AppEvents";
import davidService from "../../../../services/davidServices";

function* handleApplyFilter(action) {
  const { type, action_type, data } = action;
  try {
    yield put({
      type: action_type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: `${type}_ERROR`,
      payload: error,
    });
  }
}

function* getStories(action) {
  const {
    action_type,
    url,
    resolve,
    reject,
    isTextStory = false,
    isVideoStory = false,
    selectedTag = "",
    rangeSelected = "",
    page = 0,
  } = action;
  try {
    yield put({
      type: APP_EVENTS.GET_STORIES_LOADING,
      payload: {
        isTextStory: isTextStory,
        isVideoStory: isVideoStory,
      },
    });
    const result = yield call(davidService.get, { url });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: {
          ...result.data,
          isTextStory: isTextStory,
          isVideoStory: isVideoStory,
          selectedTag: selectedTag,
          rangeSelected: rangeSelected,
          page: page,
        },
      });
      yield call(resolve, result.data);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS.GET_STORIES_ERROR,
        payload: {
          isTextStory: isTextStory,
          isVideoStory: isVideoStory,
        },
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.GET_STORIES_ERROR,
      payload: {
        isTextStory: isTextStory,
        isVideoStory: isVideoStory,
      },
    });
  }
}

function* getStoriesWithSearchInput(action) {
  const {
    action_type,
    url,
    resolve,
    reject,
    isTextStory = false,
    isVideoStory = false,
    selectedTag = "",
    rangeSelected = "",
    page = 0,
  } = action;
  try {
    yield put({
      type: APP_EVENTS.GET_SEARCH_STORIES_LOADING,
      payload: {
        isTextStory: isTextStory,
        isVideoStory: isVideoStory,
      },
    });
    const result = yield call(davidService.get, { url });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: {
          ...result.data,
          isTextStory: isTextStory,
          isVideoStory: isVideoStory,
          selectedTag: selectedTag,
          rangeSelected: rangeSelected,
          page: page,
        },
      });
      yield call(resolve, result.data);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS.GET_SEARCH_STORIES_ERROR,
        payload: {
          isTextStory: isTextStory,
          isVideoStory: isVideoStory,
        },
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.GET_SEARCH_STORIES_ERROR,
      payload: {
        isTextStory: isTextStory,
        isVideoStory: isVideoStory,
      },
    });
  }
}

function* getPreferences(action) {
  const { action_type, url } = action;
  try {
    yield put({
      type: APP_EVENTS.GET_PREFERENCES_LOADING,
    });
    const result = yield call(davidService.get, { url });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: result,
      });
    } else {
      yield put({
        type: APP_EVENTS.GET_STORIES_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: APP_EVENTS.GET_STORIES_ERROR,
      payload: error,
    });
  }
}

function* rejectStory(action) {
  const { action_type, url, id, resolve, reject, pageType } = action;
  try {
    yield put({
      type: APP_EVENTS.REJECT_LOADING,
    });
    const result = yield call(davidService.post, { url });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: { id, pageType },
      });
      yield call(resolve);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS.REJECT_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.REJECT_ERROR,
      payload: error,
    });
  }
}

function* sendNotification(action) {
  const { action_type, url, id, resolve, reject } = action;
  try {
    yield put({
      type: APP_EVENTS.SEND_NOTIFICATION,
    });
    const result = yield call(davidService.post, { url });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: { id },
      });
      yield call(resolve);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.SEND_NOTIFICATION_ERROR,
      payload: error,
    });
  }
}

function* handleOnSummaryUpdate(action) {
  const {
    action_type,
    resolve,
    reject,
    data,
    url,
    id,
    updatedSummary,
    pageType,
  } = action;
  try {
    yield put({
      type: APP_EVENTS.REJECT_LOADING,
    });
    const result = yield call(davidService.post, { url, data });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: { updatedSummary, id, pageType },
      });
      yield call(resolve);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS.REJECT_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.REJECT_ERROR,
      payload: error,
    });
  }
}

function* handleOnTitleUpdate(action) {
  const {
    action_type,
    resolve,
    reject,
    data,
    url,
    id,
    updatedTitle,
    pageType,
  } = action;
  try {
    yield put({
      type: APP_EVENTS.REJECT_LOADING,
    });
    const result = yield call(davidService.post, { url, data });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: { updatedTitle, id, pageType },
      });
      yield call(resolve);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS.REJECT_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.REJECT_ERROR,
      payload: error,
    });
  }
}

function* handleOnDerivedTagsUpdate(action) {
  const {
    action_type,
    resolve,
    reject,
    data,
    url,
    id,
    updatedDerivedTags,
    pageType,
  } = action;
  try {
    yield put({
      type: APP_EVENTS.REJECT_LOADING,
    });
    const result = yield call(davidService.post, { url, data });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: { updatedDerivedTags, id, pageType },
      });
      yield call(resolve);
    } else {
      yield call(reject);
      yield put({
        type: APP_EVENTS.REJECT_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.REJECT_ERROR,
      payload: error,
    });
  }
}

function* postBulkSubmit(action) {
  const { action_type, url, data } = action;
  try {
    yield put({
      type: APP_EVENTS.POST_BULK_SUBMIT_LOADING,
    });
    const result = yield call(davidService.post, { url, data });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
      });
    } else {
      yield put({
        type: APP_EVENTS.POST_BULK_SUBMIT_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: APP_EVENTS.POST_BULK_SUBMIT_ERROR,
      payload: error,
    });
  }
}

function* postSingleSubmit(action) {
  const { action_type, url, data } = action;
  try {
    yield put({
      type: APP_EVENTS.POST_SINGLE_SUBMIT_LOADING,
    });
    const result = yield call(davidService.post, { url, data });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
      });
    } else {
      yield put({
        type: APP_EVENTS.POST_SINGLE_SUBMIT_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: APP_EVENTS.POST_SINGLE_SUBMIT_ERROR,
      payload: error,
    });
  }
}

function* getData(action) {
  const { action_type, url } = action;
  try {
    yield put({
      type: APP_EVENTS.GET_DATA_LOADING,
    });
    const result = yield call(davidService.get, { url });
    if (result.status >= 200 && result.status < 300) {
      yield put({
        type: action_type,
        payload: result.data || null,
      });
    } else {
      yield put({
        type: APP_EVENTS.GET_DATA_ERROR,
        payload: APP_EVENTS.ERROR,
      });
    }
  } catch (error) {
    yield put({
      type: APP_EVENTS.GET_DATA_ERROR,
      payload: error,
    });
  }
}

function* updateTable(action) {
  const { action_type, data } = action;
  try {
    yield put({
      type: action_type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: action_type,
      payload: error,
    });
  }
}

function* handleRowCheckbox(action) {
  const { action_type, data } = action;
  try {
    yield put({
      type: action_type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: action_type,
      payload: error,
    });
  }
}

function* clearData(action) {
  const { action_type, data } = action;
  try {
    yield put({
      type: action_type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: action_type,
      payload: error,
    });
  }
}

function* handleRange(action) {
  const { action_type, data } = action;
  try {
    yield put({
      type: action_type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: action_type,
      payload: error,
    });
  }
}

function* handleSynonyms(action) {
  const { action_type, data } = action;
  try {
    yield put({
      type: action_type,
      payload: data,
    });
  } catch (error) {
    yield put({
      type: action_type,
      payload: error,
    });
  }
}

function* StoriesSaga() {
  yield takeLatest(`${APP_EVENTS.SET_FILTER}_WATCHER`, handleApplyFilter);
  yield takeEvery(`${APP_EVENTS.SET_RANGE}_WATCHER`, handleRange);
  yield takeEvery(`${APP_EVENTS.SET_SYNONYMS}_WATCHER`, handleSynonyms);
  yield takeEvery(`${APP_EVENTS.GET_STORIES}_WATCHER`, getStories);
  yield takeEvery(
    `${APP_EVENTS.GET_SEARCH_STORIES}_WATCHER`,
    getStoriesWithSearchInput
  );
  yield takeLatest(`${APP_EVENTS.GET_PREFERENCES}_WATCHER`, getPreferences);
  yield takeLatest(`${APP_EVENTS.POST_BULK_SUBMIT}_WATCHER`, postBulkSubmit);
  yield takeLatest(
    `${APP_EVENTS.POST_SINGLE_SUBMIT}_WATCHER`,
    postSingleSubmit
  );
  yield takeLatest(`${APP_EVENTS.GET_DATA}_WATCHER`, getData);
  yield takeLatest(`${APP_EVENTS.UPDATE_TABLE}_WATCHER`, updateTable);
  yield takeLatest(
    `${APP_EVENTS.UPDATE_ARTICLE_MEDIA_URL}_WATCHER`,
    updateTable
  );
  yield takeLatest(
    `${APP_EVENTS.UPDATE_ARTICLE_CONTENT_TAG}_WATCHER`,
    updateTable
  );
  yield takeLatest(`${APP_EVENTS.HANDLE_CHECKBOX}_WATCHER`, handleRowCheckbox);
  yield takeEvery(`${APP_EVENTS.CLEAR_DATA}_WATCHER`, clearData);
  yield takeEvery(`${APP_EVENTS.REJECT}_WATCHER`, rejectStory);
  yield takeEvery(`${APP_EVENTS.SEND_NOTIFICATION}_WATCHER`, sendNotification);
  yield takeLatest(
    `${APP_EVENTS.UPDATE_SUMMARY}_WATCHER`,
    handleOnSummaryUpdate
  );
  yield takeLatest(`${APP_EVENTS.UPDATE_TITLE}_WATCHER`, handleOnTitleUpdate);
  yield takeLatest(
    `${APP_EVENTS.UPDATE_DERIVED_TAGS}_WATCHER`,
    handleOnDerivedTagsUpdate
  );
}

export default StoriesSaga;
