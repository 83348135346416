import { combineReducers } from "redux";
import StoriesReducer from "./components/pages/Stories/StoriesReducer/storiesReducer";
import commonReducer from "./components/pages/Login/LoginReducer/loginReducer";

const rootReducer = combineReducers({
  stories: StoriesReducer,
  common: commonReducer,
});

export default rootReducer;
