import keymirror from "keymirror";

const APP_EVENTS = keymirror({
  LOGIN: null,
  LOGIN_LOADING: null,
  LOGIN_ERROR: null,
  LOGOUT: null,
  GET_USERNAME: null,
});

export default APP_EVENTS;
