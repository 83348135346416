const STORAGE_TYPE = { LOCAL: "LOCAL", SESSION: "SESSION" };

const getStorage = (key, storageType) => {
  try {
    if (!storageType || storageType === STORAGE_TYPE.LOCAL) {
      return JSON.parse(localStorage.getItem(key));
    }
    if (storageType === STORAGE_TYPE.SESSION) {
      return JSON.parse(sessionStorage.getItem(key));
    }
    return null;
  } catch (error) {
    window.__bglogger.on("error", error);
    return null;
  }
};

const setStorage = (key, value, storageType) => {
  try {
    if (!storageType || storageType === STORAGE_TYPE.LOCAL) {
      localStorage.setItem(key, JSON.stringify(value));
    }
    if (storageType === STORAGE_TYPE.SESSION) {
      sessionStorage.setItem(key, JSON.stringify(value));
    }
  } catch (error) {
    window.__bglogger.on("error", error);
  }
};

const getSession = (key) => getStorage(key, STORAGE_TYPE.SESSION);

const setSession = (key, value) => {
  setStorage(key, value, STORAGE_TYPE.SESSION);
};

const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  return (cookies.find((cookie) => cookie.includes(name)) || "").split("=")[1];
};

const setCookie = (name, value, replace, cookieMeta = "") => {
  const cookieString = `${name}=${value};path=/;${cookieMeta}`;
  if (replace) {
    document.cookie = cookieString;
  } else if (!getCookie(name)) {
    document.cookie = cookieString;
  }
};

const removeCookie = (name) => setCookie(name, "12", true, "max-age=0");

export {
  setCookie,
  getCookie,
  getStorage,
  getSession,
  setSession,
  setStorage,
  STORAGE_TYPE,
  removeCookie,
};
