import { pageSize } from "./values";

let baseUrl = "https://recdev.bigshyft.com";
const minisGenUrl = "https://gen.techminis.com";

if (
  process.env.NODE_ENV === "development" ||
  process.env.NODE_ENV === "staging"
) {
  baseUrl = "http://cms.techminisdev.com";
}

// "https://techminis.com/";
// http://techminis.david.infoedge.com/api/cms/industriesWithSynonyms
// const apiUrl = "https://techminis.com";

const URLS = {
  industries: `${baseUrl}/api/cms/industriesWithSynonyms`,
  textStories: ({
    from = "",
    to = "",
    industries = "",
    synonyms = "",
    page = "0",
    excludeTop = false,
    summaryExists = true,
  }) =>
    `${baseUrl}/api/cms/articlesWithPagination?industries=${industries}&from=${from}&excludeTop=${excludeTop}&to=${to}&summaryExists=${summaryExists}&synonyms=${synonyms}&page=${page}&size=${pageSize}`,
  textStoriesWithSearch: ({
    from = "",
    to = "",
    keyword = "",
    synonyms = "",
    page = "0",
    summaryExists = true,
  }) =>
    `${baseUrl}/api/cms/article/search?keyword=${keyword}&from=${from}&to=${to}&page=${page}&size=20`,
  videoStories: ({
    from = "",
    to = "",
    page = "0",
    industries = "",
    summaryExists = true,
    excludeTop = false,
  }) =>
    `${baseUrl}/api/cms/storiesWithPagination?industries=${industries}&from=${from}&to=${to}&&excludeTop=${excludeTop}&summaryExists=${summaryExists}&page=${page}`,
  bulkStories: `${baseUrl}/api/cms/article/bulk`,
  singleStory: ({ id = "" }) => `${baseUrl}/api/cms/article/${id}`,
  updateSummary: ({ id = "" }) =>
    `${baseUrl}/api/cms/article/${id}/updateSummary`,
  updateDetails: ({ id = "" }) =>
    `${baseUrl}/api/cms/article/${id}/updateDetails`,
  rejectStory: ({ id = "", type = "textPage" }) =>
    `${baseUrl}/api/cms/article/${id}/${
      type === "textPage" ? "reject" : "rejectStory"
    }`,
  uploadVideo: `${baseUrl}/api/cms/video`,
  uploadImage: `${baseUrl}/api/cms/image`,
  sendNotification: ({ id = "" }) =>
    `${baseUrl}/api/cms/notif/v2/broadcast/${id}`,
  sendToNaukri: `${baseUrl}/api/cms/notifOfDay`,
  addVideo: `${baseUrl}/api/cms/videoArticle`,
  addArticle: `${baseUrl}/api/cms/article`,
  sendNotification: ({ id = "" }) =>
    `${baseUrl}/api/cms/notif/v2/broadcast/${id}`,
  sendNotificationForSegment: ({ id = "" }) =>
    `${baseUrl}/api/cms/notif/v2/segment/${id}`,
  verifySegmentUsers: () => `${baseUrl}/api/cms/notif/v2/segment/evaluate`,
  verifyToken: `${baseUrl}/api/cms/issueToken`,
  getData: ({ id = "" }) => `${baseUrl}/api/cms/article/${id}`, //fetching data corresponding to particular article or story,
  topBuzzFeeds: ({ page = 0, size = 10 }) => {
    return `${baseUrl}/api/cms/article/search/keywords?page=${page}&size=${size}`;
  },
  prioritiedList: `${baseUrl}/api/cms/prioritized`,
  rssFeed: `${baseUrl}/api/cms/rssFeed`,
  contentTags: `${baseUrl}/api/cms/article/contentTags`,
  eventCodeSuggestions: `${baseUrl}/api/cms/v2/notif/eventcode_suggestions`,

  templateSubmit: `${minisGenUrl}/minis-template-publish/templateGeneration/submit`,
  getAllTemplates: `${minisGenUrl}/minis-template-publish/template/thumbnail/response`,
  getTemplatePreview: (id) =>
    `${minisGenUrl}/minis-template-publish/template/preview/${id}`,
  generateContent: `${minisGenUrl}/minis-content-gen-service/generate/content`,
  getGeneratedTemplate: (id) =>
    `${minisGenUrl}/minis-content-merging-service/merge/template/content/status/${id}`,
  generatePdf: `https://asia-south1-tech-minis.cloudfunctions.net/html-to-pdf-util`,
  storiesListing: (page) =>
    `${minisGenUrl}/minis-content-merging-service/generatedContentListing?page=${page}&size=10`,
  templateSwitching: (contentId, templateId) =>
    `${minisGenUrl}/minis-content-merging-service/merge/template/changed/${contentId}/${templateId}`,
  getGeneratedTemplateData: (mergedContentId) =>
    `${minisGenUrl}/minis-content-merging-service/merge/template/content/mergedContentId/${mergedContentId}`,
  deleteMergedContent: (mergedContentId) =>
    `${minisGenUrl}/minis-content-merging-service/merge/template/content/delete/${mergedContentId}`,
  deleteGeneratedContent: (generatedContentId) =>
    `${minisGenUrl}/minis-content-gen-service/generate/content/delete/${generatedContentId}`,
  deleteTemplate: (id) =>
    `${minisGenUrl}/minis-template-publish/template/delete/${id}`,
  getTemplateData: (templateId) =>
    `${minisGenUrl}/minis-template-publish/retrieve/template/${templateId}`,
  templateUpdate: `${minisGenUrl}/minis-template-publish/templateGeneration/update`,
  imgGenThemes: `${minisGenUrl}/minis-content-gen-service/generate/content/imageGen/themes`,
};

export default URLS;
