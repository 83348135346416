import axios from "axios";
import get from "lodash/get";

const defaultConfig = (authToken = "") => {
  return {
    mode: "no-cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${authToken}`,
    },
    credentials: "same-origin",
    data: {},
  };
};

const davidService = {
  get({ url, config }) {
    const _localItems = JSON.parse(localStorage.getItem("appstate"));
    config = config || defaultConfig(get(_localItems, "common.authKey"));
    return axios.get(url, config);
  },

  post({ url, data, config }) {
    const _localItems = JSON.parse(localStorage.getItem("appstate"));
    config = config || defaultConfig(get(_localItems, "common.authKey"));
    return axios.post(url, data, config);
  },

  put({ url, data, config }) {
    const _localItems = JSON.parse(localStorage.getItem("appstate"));
    config = config || defaultConfig(get(_localItems, "common.authKey"));
    return axios.put(url, data, config);
  },
  delete({ url, data, config }) {
    const _localItems = JSON.parse(localStorage.getItem("appstate"));
    config = config || defaultConfig(get(_localItems, "common.authKey"));
    return axios.delete(url, { ...config, data });
  },

  getUsingFetch({ url, config }) {
    const _localItems = JSON.parse(localStorage.getItem("appstate"));
    config = config || defaultConfig(get(_localItems, "common.authKey"));
    var requestObj = {
      method: "GET",
      // body: JSON.stringify(data)
    };
    if (config.headers) {
      requestObj.headers = config.headers;
    }
    if (config.credentials) {
      requestObj.credentials = config.credentials;
    }
    return fetch(url, requestObj)
      .then(function (response) {
        return response.json();
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (
            error.response.status === 401 &&
            window.location.pathname !== "/"
          ) {
            localStorage.clear();
            window.location.replace("/");
          }
        }
      });
  },

  axios({ url, data, config, userType, method }) {
    config = config || defaultConfig;
    if (method === "POST") {
      return davidService.post({ url, data, config, userType });
    } else if (method === "GET") {
      return davidService.get({ url, data, config, userType });
    } else if (method === "PUT") {
      return davidService.put({ url, data, config, userType });
    } else if (method === "DELETE") {
      return davidService.delete({ url, data, config, userType });
    }
  },
};

export default davidService;
