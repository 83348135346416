import { takeLatest, put, call } from "redux-saga/effects";
import APP_EVENTS from "./AppEvents";
import get from "lodash/get";
import davidService from "../../../../services/davidServices";

function* login(action) {
  const { action_type, url, resolve, reject, data } = action;

  try {
    yield put({
      type: APP_EVENTS.LOGIN_LOADING,
    });

    const result = yield call(davidService.post, { url, data });
    if (
      result &&
      result.data &&
      result.data.status >= 200 &&
      result.data.status < 300
    ) {
      yield put({
        type: action_type,
        payload: {
          token: get(result, "data.data") || "",
          userName: get(data, "userName") || "",
        },
      });
      yield call(resolve);
    } else {
      yield put({
        type: APP_EVENTS.LOGIN_ERROR,
        payload: null,
      });
      yield call(reject);
    }
  } catch (error) {
    yield call(reject);
    yield put({
      type: APP_EVENTS.LOGIN_ERROR,
      payload: error,
    });
  }
}

function* logout(action) {
  const { action_type } = action;
  try {
    yield put({
      type: action_type,
      payload: null,
    });
  } catch (error) {
    yield put({
      type: action_type,
      payload: error,
    });
  }
}

function* commonSaga() {
  yield takeLatest(`${APP_EVENTS.LOGIN}_WATCHER`, login);
  yield takeLatest(`${APP_EVENTS.LOGOUT}_WATCHER`, logout);
}

export default commonSaga;
