import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Login from "./LoginPage";
import { login, logout } from "./LoginReducer/loginActions";

class LoginContainer extends PureComponent {
  render() {
    return <Login {...this.props} />;
  }
}

const mapSTP = ({ common }) => ({
  userName: common.userName || "",
});

const mapDTP = {
  login,
  logout,
};

export default connect(mapSTP, mapDTP)(LoginContainer);
