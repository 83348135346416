import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Username from "../components/organisms/Username";
import "./Header.css";

const styles = {
  headerRoot: {
    flexGrow: 1,
    width: "100%",
  },
  header: {
    backgroundColor: "#595959",
    height: 70,
  },
  content: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  typography: {
    useNextVariants: true,
  },
};

class Header extends Component {
  render() {
    const { classes, history, isLoggedin, userName, handleLogOut } = this.props;
    return (
      <div className={classes.headerRoot}>
        <AppBar position="static" className={classes.header}>
          <Toolbar variant="dense" className={classes.content}>
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              <div className="title">
                <hr />
                <div className="title_text">
                  <span>Project</span>
                  <span>Techminis CMS</span>
                </div>
              </div>
            </Link>
            {isLoggedin && (
              <Username
                agentName={userName}
                history={history}
                handleLogOut={handleLogOut}
              />
            )}
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
